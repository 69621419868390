import { getImage, ImageQuery } from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  fonts,
  OwnUpText
} from '@rategravity/own-up-component-library';
import React from 'react';
import { agentData } from '../../../data/content';
import { metadata } from '../../../data/metadata/images';
import { colors } from '../../../modules/colors';
import { Spacer } from '../../layout/spacers';
import { PhotoFeature } from '../../photo-feature';
import { ResponsiveSectionHeader } from '../../typography';

type AgentData = typeof agentData;

const NameTitleTextStyle = createOwnUpStyle({
  ...fonts.TIEMPOS_HEADLINE_MEDIUM,
  color: colors.PRIMARY,
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 32,
  lineHeight: 1.3,
  variants: {
    mediumAndDown: {
      fontSize: 26
    }
  }
});

export const NameTitleText = createOwnUpComponent(OwnUpText, NameTitleTextStyle);

const QuoteTextStyle = createOwnUpStyle({
  fontSize: 26,
  fontWeight: 'bold',
  fontStyle: 'italic'
});

const QuoteText = createOwnUpComponent(OwnUpText, QuoteTextStyle);

export const Agent = ({
  Image,
  data: { name, title, quote },
  backgroundColor,
  imagePosition
}: {
  Image: JSX.Element | null;
  data: AgentData[0];
  backgroundColor: string;
  imagePosition: 'right' | 'left';
}) => {
  const text = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <NameTitleText>
        {name} <br />
        {title}
      </NameTitleText>
      <Spacer />
      <QuoteText>
        <i>{quote}</i>
      </QuoteText>
    </div>
  );

  return (
    <PhotoFeature
      backgroundColor={backgroundColor}
      imagePosition={imagePosition}
      label={name}
      text={text}
      photoSpacing={35}
    >
      {Image}
    </PhotoFeature>
  );
};

const imgStyle = {
  maxWidth: 350,
  margin: 'auto',
  position: 'relative' as const,
  borderRadius: '50%'
};

export const Agents = ({
  imageData,
  agentData
}: {
  imageData: ImageQuery;
  agentData: AgentData;
}) => (
  <section
    aria-label="real estate agent testimonials"
    style={{ display: 'flex', flexDirection: 'column' }}
  >
    <ResponsiveSectionHeader style={{ padding: '0 30px' }}>
      What other agents are saying
    </ResponsiveSectionHeader>
    <Spacer />
    {agentData.map((data, i) => {
      const Image = getImage(imageData, data.image, metadata, imgStyle);
      const props =
        i % 2 === 0
          ? {
              backgroundColor: colors.LIGHT_GREY,
              imagePosition: 'left' as const
            }
          : {
              backgroundColor: colors.BACKGROUND,
              imagePosition: 'right' as const
            };
      return <Agent Image={Image} data={data} {...props} key={i} />;
    })}
  </section>
);
